import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Head from '../components/Head';
import NavBar from '../components/NavBar';
import CtaContact from '../components/CtaContact';

import '../scss/about.scss';

const Bold = ({ children }) => <span className="bold paragraph">{children}</span>
const Text = ({ children }) => <p className="paragraph">{children}</p>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <><Text>{children}</Text><br></br></>
    },
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
}

const TimelineCard = ({ header, paragraph, step }) => {

    let split = paragraph.split(', ');
    let words = [];
    split.map((char, i) => {
        if (char != ', ')
            return words.push(<h3 className='paragraph' key={i}>{char}</h3>);
    })
    
    return (
        <div className='timeline-card'>
            <div className='timeline-text'>
                <div className='step'>{step}</div>
                <h2 className='subheader'>{header}</h2>
                {words}
            </div>
        </div>
    )
}

function About (props) {
    const data = props.data.allContentfulAboutUsPage.edges[0].node
    const [navOpen, setNavOpen] = useState(false);
    const navCallback = (state) => {
        setNavOpen(state);
    }

    const heroBackground = {
        background: `url(https:${data.background.file.url})`
    }

    const Description1 = documentToReactComponents(data.description1.json, options)[0]
    const Description2 = documentToReactComponents(data.description2.json, options)

    return (
        <div className='about'>
            <Head url='https://northboundfilms.com/about-us'/>
            <div className='nav-container'>
                <NavBar secondary={true} parentCallback={navCallback}/>
                <div className={(navOpen ? 'disabled-content' : null)} />
                <div className={`mobile-margin-top container transition ${(navOpen ? 'shrink' : null)}`}>
                    <div className='hero'>
                        <Img fluid={data.background.fluid} className='bg'></Img>
                        <div className='overlay'/>
                        <div className='title'>
                            <h1 className='header'>{data.header}</h1>
                            <h2 className='subheader'>{data.subheader}</h2>
                        </div>
                    </div>
                    <div className='centered'>
                        <div className='content'>
                            {Description1}

                            <div className='process'>
                                <div className='connector'></div>
                                <h1 className='subheader'>Our Process</h1>
                                <TimelineCard header="Pre-Production" paragraph={data.preProduction} step={'1'} key={0}/>
                                <TimelineCard header="Production" paragraph={data.production} step={'2'} key={1}/>
                                <TimelineCard header="Post-Production" paragraph={data.postProduction} step={'3'} key={2}/>    
                            </div>
                        </div>
                    </div>
                    <div className='story centered'>
                        <div className='content'>
                            <h1 className='subheader'>Our Story</h1>
                            {Description2}
                        </div>
                    </div>
                    <CtaContact />

                </div>
            </div>
        </div>
    )
}

export default About

export const query = graphql`
    query {
        allContentfulAboutUsPage {
            edges {
              node {
                header
                subheader
                background {
                    fluid {
                        ...GatsbyContentfulFluid_withWebp
                    }
                    file {
                        url
                    }
                }
                description1 {
                  json
                }
                description2 {
                  json
                }
                postProduction
                preProduction
                production
              }
            }
        }
    }
`;